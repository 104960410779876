import { get } from 'lodash';

function imageFor(graphqlData, data, propertyNames) {
  return propertyNames.reduce((prev, propertyName) => {
    const imageField = data[propertyName];
    return {
      ...prev,
      [propertyName]: get(graphqlData, [imageField, 'childImageSharp', 'fixed'])
    };
  }, {});
}

export default function populateGatsbyImage(graphqlData, data, propertyNames) {
  propertyNames = Array.isArray(propertyNames) ? propertyNames : [propertyNames];

  if (Array.isArray(data)) {
    return data.map((item) => {
      return {
        ...item,
        ...imageFor(graphqlData, item, propertyNames),
      };
    });
  }

  return {
    ...data,
    ...imageFor(graphqlData, data, propertyNames),
  };
}
