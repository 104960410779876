import React from 'react';
import styles from './contact-us.module.scss';

export default function ContactUs(props) {
  return <div className={styles.contactUs} {...props}>
    <div className={styles.tel}>联系方式：<em>191-2151-9504</em></div>
    <div className={styles.mail}>邮箱： xiaochengxu@baohealth.com.cn</div>
    <div className={styles.address}>地址：上海市奉贤区奉浦大道518号</div>
  </div>
}
