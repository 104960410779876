import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import populateGatsbyImage from '../utils/populateGatsbyImage';
import styles from './social-matrix.module.scss';

const socialMatrixData = [
  {
    image: 'qrcode1',
    primaryText: <>微信扫码，立即关注<br />「知了健康测试」服务号</>,
    secondaryText: <>了解更多专业健康测评<br />可永久查看测试报告</>,
  }, {
    image: 'qrcode2',
    primaryText: <>微信扫码，立即关注<br />「知了健康测评」小程序</>,
    secondaryText: <>了解更多专业健康测评<br />可永久查看测试报告</>,
  }, {
    image: 'qrcode3',
    primaryText: <>微信扫码，立即咨询<br />加葆康客服微信</>,
    secondaryText: '有问题，就问「小葆」',
  }
];

function SocialItem({ image, primaryText, secondaryText }) {
  return <div className={styles.socialItem}>
    <div className={styles.image}><Image fixed={image} /></div>
    <div className={styles.primary}>{primaryText}</div>
    <div className={styles.secondary}>{secondaryText}</div>
  </div>
}

export default function SocialMatrix() {
  const data = useStaticQuery(graphql`
    query {
      qrcode1: file(relativePath: {eq: "qrcode-offical.jpg"}) {
        childImageSharp {
          fixed(width: 260, height: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      qrcode2: file(relativePath: {eq: "qrcode-app.jpg"}) {
        childImageSharp {
          fixed(width: 260, height: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      qrcode3: file(relativePath: {eq: "qrcode-service.png"}) {
        childImageSharp {
          fixed(width: 260, height: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
    }
  `);

  return <section className={styles.matrix}>
    {socialMatrixData.map((item, i) => <SocialItem key={i} {...populateGatsbyImage(data, item, 'image')} />)}
  </section>
}
