import { graphql } from 'gatsby';
import React from 'react';
import ContactUs from '../components/contact-us';
import GenericSection from '../components/generic-section';
import PageLayout from '../components/page-layout';
import SimpleBanner from '../components/simple-banner';
import SocialMatrix from '../components/social-matrix';
import SEO from '../components/seo';

export default function AboutPage({ data }) {
  const { banner } = data;

  return <PageLayout pageKey="/about">
    <SEO title="关于我们" />

    <SimpleBanner
      title="关于我们"
      image={banner.childImageSharp.fixed}
      contentStyle={{ background: 'rgba(38, 38, 38, 0.6)' }}
    />
    <GenericSection
      title="上海葆康医疗管理有限公司介绍"
      detail={<>
        <p>上海葆康医疗管理有限公司致力于为医疗机构提供专业的互联网化运营 服务，帮助医疗机构提高市场化竞争力，是一家创新型、学习型的企业。 </p>
        <br />
        <p>团队成员深耕互联网医疗大健康行业多年，核心成员有 10 余年大健康行 业创业经验，曾就任于丁香园、微医集团、好大夫等互联网医疗资深企业，主导和参与了上百起数字营销项目，积累了深厚的行业资源， 具备综合的互联网产品运营经验、营销管理经验，及高效的项目落地执 行能力。</p>
        <br />
        <p>同时，公司还组织了强大的临床医生“外援团”，提供专业支持和帮助。</p>
      </>}
    />

    <GenericSection
      title="业务范围"
      detail={<>
        <p>上海葆康医疗管理有限公司致力于为大众提供健康管理服务，为医疗健康类企事业单位提供专业的互联网化运营服务。以帮助医疗健康相关企事业单位构建品牌形象，开拓市场份额，改善患者就医体验，提高医患沟通效率，扩大健康科普宣传力度等。</p>
        <br />
        <p>服务主要包括微信公众号运营、微信小程序运营、新媒体运营、新媒体运营方案咨询、小程序开发与代运营、APP/互联网医院代运营、科研美学服务等。</p>
      </>}
      style={{ background: '#f0f0f0' }}
    />

    <GenericSection title="联系我们">
      <ContactUs style={{ marginBottom: 70 }} />
      <SocialMatrix />
    </GenericSection>
  </PageLayout>
}

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "banner-about.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 816) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
  }
`;
