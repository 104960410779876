import React from 'react';
import styles from './generic-section.module.scss';

export default function GenericSection({ title, desc, detail, style, children }) {
  return <section className={styles.section} style={style}>
    <h3>{title}</h3>
    <div className={styles.desc}>{desc}</div>
    {detail ? <div className={styles.detail}>{detail}</div> : null}
    {children ? <div className={styles.extra}>{children}</div> : null}
  </section>
}
