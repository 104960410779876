import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styles from './simple-banner.module.scss';

export default function SimpleBanner({ image, title, desc, contentStyle }) {
  return <BackgroundImage
    Tag="section"
    className={styles.banner}
    fixed={image}
  >
    <div className={styles.content} style={contentStyle}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        {desc ? <div className={styles.desc}>{desc}</div> : null}
      </div>
    </div>
  </BackgroundImage>
}
